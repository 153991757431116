<template>
  <div class="tips">
    <img src="@/assets/images/nodata.png" alt="" />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang = "scss" scoped>
.tips {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  img {
    width: 1200px;
    height: auto;
  }
}
</style>